var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-tooltip", { attrs: { title: _vm.value } }, [
    _c("span", [_vm._v(_vm._s(_vm.value || "-"))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }