var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("LeasingDebtRecapReportFormHeader", {
                attrs: { loading: _vm.loading.find },
                on: { find: _vm.handleFind, reset: _vm.handleReset }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  "data-source": _vm.data.data,
                  columns: _vm.columns,
                  "row-key": "id",
                  loading: _vm.loading.find,
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.data.totalElements
                      })
                    },
                    total: _vm.data.totalElements,
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    defaultPageSize: _vm.pagination.limit
                  },
                  scroll: { y: 700, x: "calc(100% + 2200px)" },
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  "data-testid": "leasing-debt-recap-report-table"
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "ellipsis",
                    fn: function(text) {
                      return [_c("VEllipsis", { attrs: { value: text } })]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    "data-testid": "leasing-debt-recap-report-btn-download",
                    type: "primary",
                    loading: _vm.loading.download,
                    disabled: !_vm.canDownload
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_download")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }