















































import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import { DATE_FORMAT_MMM_YYYY } from "@/models/constants/date.constant";
import { LeasingDebtRecapReportFormHeaderState } from "@/models/interface/leasing-debt-recap-report/LeasingDebtRecapReportFormHeaderState.interface";
import { FormModel } from "ant-design-vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {
    SelectLessor,
  },
})
export default class LeasingDebtRecapReportFormHeader extends Vue {
  DATE_FORMAT_MMM_YYYY = DATE_FORMAT_MMM_YYYY;

  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ default: false, type: Boolean })
  readonly loading!: boolean;

  formValue: LeasingDebtRecapReportFormHeaderState =
    new LeasingDebtRecapReportFormHeaderState();

  formRules = {
    period: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  handleReset(): void {
    this.formValue = new LeasingDebtRecapReportFormHeaderState();
    this.$emit("reset");
  }

  handleFind(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        return;
      }

      this.$emit("find", this.formValue);
    });
  }
}
