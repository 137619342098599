var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_leasing_debt_recap_report") } },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "DETAIL" } },
        [
          _c(
            "a-tab-pane",
            { key: "DETAIL", attrs: { tab: _vm.$t("lbl_detail") } },
            [_c("LeasingDebtRecapReportDetail")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "SUMMARY", attrs: { tab: _vm.$t("lbl_summary") } },
            [_c("LeasingDebtRecapReportSummary")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }