













import LeasingDebtRecapReportDetail from "@/components/LeasingDebtRecap/LeasingDebtRecapReportDetail.vue";
import LeasingDebtRecapReportSummary from "@/components/LeasingDebtRecap/LeasingDebtRecapReportSummary.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LeasingDebtRecapReportDetail,
    LeasingDebtRecapReportSummary,
  },
})
export default class LeasingDebtRecapReport extends Vue {}
