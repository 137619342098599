




















































import LeasingDebtRecapReportFormHeader from "@/components/LeasingDebtRecap/LeasingDebtRecapReportFormHeader.vue";
import VEllipsis from "@/components/VEllipsis/VEllipsis.vue";
import dateFormat from "@/filters/date.filter";
import { APagination, Row } from "@/hooks/table";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DATE_FORMAT_MMM_YYYY } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { LeasingDebtRecapReportDetailResponseDto } from "@/models/interface/leasing-debt-recap-report/LeasingDebtRecapReportDetailResponseDto.interface";
import { LeasingDebtRecapReportFormHeaderState } from "@/models/interface/leasing-debt-recap-report/LeasingDebtRecapReportFormHeaderState.interface";
import { leasingServices } from "@/services/leasing.service";
import { LabelInValue } from "@/types";
import { currencyFormat } from "@/validator/globalvalidator";
import { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LeasingDebtRecapReportFormHeader,
    VEllipsis,
  },
})
export default class LeasingDebtRecapReportDetail extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 50,
    },
    {
      title: this.$t("lbl_lessor_name"),
      dataIndex: "lessorName",
      key: "lessorName",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
    },
    {
      title: this.$t("lbl_lessor_code"),
      dataIndex: "lessorCode",
      key: "lessorCode",
      width: 120,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_leasing_number"),
      dataIndex: "leasingNumber",
      key: "leasingNumber",
      width: 150,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      key: "leasingContractNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_leasing_date"),
      dataIndex: "leasingContractDate",
      key: "leasingContractDate",
      width: 120,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_beginning_balance_principal_cost"),
      dataIndex: "beginningPrincipalCost",
      key: "beginningPrincipalCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_beginning_balance_interest"),
      dataIndex: "beginningInterestCost",
      key: "beginningInterestCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_beginning_balance_total"),
      dataIndex: "beginningPaymentCost",
      key: "beginningPaymentCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_new_contract_principal_cost"),
      dataIndex: "newPrincipalCost",
      key: "newPrincipalCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_new_contract_interest"),
      dataIndex: "newInterestCost",
      key: "newInterestCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_new_contract_total"),
      dataIndex: "newPaymentCost",
      key: "newPaymentCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_installment_principal_cost"),
      dataIndex: "installmentPrincipalCost",
      key: "installmentPrincipalCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_installment_interest"),
      dataIndex: "installmentInterestCost",
      key: "installmentInterestCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_installment_total"),
      dataIndex: "installmentPaymentCost",
      key: "installmentPaymentCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_ending_balance_principal_cost"),
      dataIndex: "endingPrincipalCost",
      key: "endingPrincipalCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_ending_balance_interest"),
      dataIndex: "endingInterestCost",
      key: "endingInterestCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_ending_balance_total"),
      dataIndex: "endingPaymentCost",
      key: "endingPaymentCost",
      align: "right",
      customRender: text => currencyFormat(text),
    },
  ];

  data: Pagination<Row<LeasingDebtRecapReportDetailResponseDto>> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    reset() {
      this.page = DEFAULT_PAGE;
      this.limit = DEFAULT_PAGE_SIZE;
    },
  };

  lessor: LabelInValue | undefined = undefined;
  periodLeasing: Moment | undefined = undefined;
  totalCount: number = DEFAULT_PAGE_SIZE;

  get canDownload(): boolean {
    return this.data.data.length > 0;
  }

  buildQueryParams(): RequestQueryParamsModel {
    const { toStartMonth } = useDate();

    const params = new RequestQueryParams();
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    if (this.periodLeasing) {
      params["periodLeasing"] = toStartMonth(this.periodLeasing).format();
    }
    if (this.lessor) {
      params["lessorId"] = this.lessor.key;
    }

    return params;
  }

  getLeasingDebtRecapReportDetail(params: RequestQueryParamsModel) {
    this.loading.find = true;
    leasingServices
      .getLeasingDebtRecapReportDetail(params)
      .then(res => {
        this.data = {
          ...res,
          data: res.data.map((item, i) => ({
            ...item,
            key: i,
            no: (this.pagination.page - ONE) * this.pagination.limit + i + ONE,
          })),
        };
        this.totalCount = res.totalElements;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;
    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();
    this.getLeasingDebtRecapReportDetail(params);
  }

  handleFind(values: LeasingDebtRecapReportFormHeaderState): void {
    this.lessor = values.lessor || undefined;
    this.periodLeasing = values.period || undefined;

    const params = this.buildQueryParams();
    this.getLeasingDebtRecapReportDetail(params);
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const DEFAULT = "All";

    const company = this.$store.state.authStore.authData.companyName;
    const filters: string[] = [
      company || DEFAULT,
      this.lessor?.label || DEFAULT,
      this.periodLeasing?.format(DATE_FORMAT_MMM_YYYY) || DEFAULT,
    ];

    const params = this.buildQueryParams();
    params.params = filters.join(",");
    params.limit = this.totalCount;

    this.loading.download = true;
    leasingServices
      .downloadLeasingDebtRecapReportDetail(params)
      .then(res => {
        toDownload(res, "leasing_debt_recap_report_detail.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  handleReset(): void {
    this.data = {
      currentPage: 0,
      data: [],
      totalElements: 0,
      totalPages: 0,
    };
    this.pagination.reset();
    this.lessor = undefined;
    this.periodLeasing = undefined;
  }
}
