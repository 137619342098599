var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "formModel",
      attrs: {
        model: _vm.formValue,
        "wrapper-col": { span: 12 },
        "label-col": { span: 6 },
        rules: _vm.formRules,
        "label-align": "left"
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_period"), prop: "period" } },
                [
                  _c("a-month-picker", {
                    attrs: {
                      format: _vm.DATE_FORMAT_MMM_YYYY,
                      "allow-clear": "",
                      "data-testid": "leasing-debt-recap-report-period"
                    },
                    model: {
                      value: _vm.formValue.period,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "period", $$v)
                      },
                      expression: "formValue.period"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_lessor_name"), prop: "lessor" } },
                [
                  _c("SelectLessor", {
                    attrs: {
                      "label-in-value": "",
                      "data-testid": "leasing-debt-recap-report-select-lessor"
                    },
                    model: {
                      value: _vm.formValue.lessor,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "lessor", $$v)
                      },
                      expression: "formValue.lessor"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "leasing-debt-recap-report-btn-reset"
                      },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "leasing-debt-recap-report-btn-find",
                        type: "primary",
                        loading: _vm.loading
                      },
                      on: { click: _vm.handleFind }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }