
import { buildPagination, SortField } from "@/builder";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "@/models/constant/global.constant";
import { UrlQueryKey } from "@/models/enums/global.enum";
import { Component, Vue } from "vue-property-decorator";

/**
 * @deprecated
 */
export const FIRST_PAGE = 1;

@Component
export default class MQueryPage extends Vue {
  query = this.$route.query;
  pagination = buildPagination({
    limit: this.query[UrlQueryKey.LIMIT]
      ? +this.query[UrlQueryKey.LIMIT]
      : DEFAULT_PAGE_SIZE,
    search: (this.query[UrlQueryKey.SEARCH] as string) || "",
    page: this.query[UrlQueryKey.PAGE]
      ? +this.query[UrlQueryKey.PAGE]
      : DEFAULT_PAGE,
  });

  resetCurrentPage(): void {
    this.pagination.page = FIRST_PAGE;
  }

  getSort({ field, dir }: SortField): string {
    let value = "";
    if (dir === "ascend") {
      value = `${field}:asc`;
    } else if (dir === "descend") {
      value = `${field}:desc`;
    }

    return value;
  }
}
