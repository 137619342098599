



























































import { ModalsForm } from "@/components/company";
import { APagination, useCompany } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { ListCompanyDto } from "@/models/interface/company";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ModalsForm,
  },
})
export default class IndexPage extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  inputSearch = "";

  loading = {
    find: false,
  };

  pagination = {
    page: FIRST_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = FIRST_PAGE;
    },
  };

  modal = {
    visible: false,
    data: useCompany().initListCompanyDto(),
    toggle(): void {
      this.visible = !this.visible;
    },
    reset(): void {
      this.data = useCompany().initListCompanyDto();
      this.visible = false;
    },
  };

  dataCompany = useCompany().initPageListCompanyDto();

  columns = [
    {
      title: this.$t("lbl_name"),
      dataIndex: "name",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_code"),
      dataIndex: "code",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_address"),
      dataIndex: "address",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_tax_number"),
      dataIndex: "taxNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_phone"),
      dataIndex: "phone",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_fax"),
      dataIndex: "fax",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_website"),
      dataIndex: "website",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_email"),
      dataIndex: "email",
      scopedSlots: { customRender: "nullable" },
    },
    {
      scopedSlots: { customRender: "edit" },
    },
  ];

  mounted(): void {
    this.fetchData();
  }

  buildQueryParams(): RequestQueryParams {
    const { searchBy } = useCompany();
    const params = new RequestQueryParams();
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.search = searchBy({
      code: this.inputSearch,
      name: this.inputSearch,
      address: this.inputSearch,
      taxNumber: this.inputSearch,
      phone: this.inputSearch,
      fax: this.inputSearch,
      website: this.inputSearch,
      email: this.inputSearch,
    });

    return params;
  }

  searchData(value: string | null): void {
    this.inputSearch = value || "";

    this.preFetch();
  }

  preFetch(): void {
    const params: RequestQueryParams = this.buildQueryParams();
    this.fetchData(params);
  }

  fetchData(params?: RequestQueryParams): void {
    const { findAll } = useCompany();

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataCompany = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;
    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.reset();
    }

    this.pagination.limit = pageSize;

    this.preFetch();
  }

  handleEdit(row: ListCompanyDto): void {
    this.modal.data = row;
    this.modal.toggle();
  }

  handleSuccess(): void {
    const params = new RequestQueryParams();
    params.limit = this.pagination.limit;
    this.fetchData(params);
  }

  handleFormClose(): void {
    this.modal.reset();
  }
}
