




















































































import { ImageUploader } from "@/components/ImageUploader";
import { useCompany } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { CreateCompanyDto, ListCompanyDto } from "@/models/interface/company";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { FormValue } from "@/store/company.store";
import { checkTaxNumber, phoneValidatorv2 } from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";

@Component({
  components: {
    ImageUploader,
  },
  computed: {
    ...mapGetters({
      form: "companyStore/getForm",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "companyStore/setForm",
    }),
    ...mapActions({
      mapOneToForm: "companyStore/mapOneToForm",
    }),
  },
})
export default class ModalsForm extends Mixins(MNotificationVue) {
  form!: FormValue;
  setForm!: (payload: Partial<FormValue>) => FormValue;
  mapOneToForm!: (payload: ListCompanyDto) => FormValue;

  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: Boolean, required: false, default: false })
  visible!: boolean;

  @Prop({ type: Object, required: false })
  company!: ListCompanyDto;

  formRules = {
    name: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    code: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    address: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    taxNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
      {
        validator: checkTaxNumber,
        message: this.$t("lbl_invalid_tax_number_format"),
      },
    ],
    phone: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
      {
        validator: phoneValidatorv2,
        message: this.$t("lbl_invalid_phone_format"),
      },
    ],
    email: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  loading = false;

  allowEditImage = false;

  get isUpdate(): boolean {
    return !!(this.company?.id || "");
  }

  get title(): string {
    const txtEdit = this.$t("lbl_edit_x", {
      x: this.$t("lbl_company"),
    }).toString();
    const txtCreate = this.$t("lbl_create_x", {
      x: this.$t("lbl_company"),
    }).toString();

    return this.isUpdate ? txtEdit : txtCreate;
  }

  @Watch("company.id")
  onChangeCompany(): void {
    this.mapOneToForm(this.company);
  }

  validateForm(): void {
    this.formModel.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (this.isUpdate) {
        this.handleUpdate(this.company.id, this.form);
      } else {
        this.handleCreate(this.form);
      }
    });
  }

  handleUpdate(id: string, form: FormValue): void {
    const { update, mapFormToUpdateDto } = useCompany();
    const req = mapFormToUpdateDto(form);
    this.loading = true;
    update(id, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success");
        this.handleSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleCreate(form: FormValue): void {
    const { create, mapFormToCreateDto } = useCompany();
    const req: CreateCompanyDto = mapFormToCreateDto(form);

    this.loading = true;
    create(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.handleSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  setLogo(data: FileCreateResponseDto | null): void {
    this.setForm({ logo: data?.url || "" });
  }

  handleCancel(): void {
    this.$emit("close");
  }

  handleSuccess(): void {
    this.$emit("success");
  }

  editImage(): void {
    this.setForm({ logo: "" });
    this.allowEditImage = true;
  }

  formatTax(action: "format" | "reverse"): void {
    const { toPlainTax, toTaxFormat } = useCompany();
    const tax: string =
      action === "format"
        ? toTaxFormat(this.form.taxNumber)
        : toPlainTax(this.form.taxNumber);
    this.setForm({ taxNumber: tax });
  }
}
