






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VEllipsis extends Vue {
  @Prop({ default: undefined, type: String, required: false })
  readonly value!: string;
}
